

import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: "home",
  props: {},
  components: {  },
})
export default class Home extends Vue {
  adminName:any="";
 mounted() {
  this.adminName= window.sessionStorage.getItem("userName");
   
  }
}
